(function ($) {
  $('body').on('click', function (e) {
    var $blockElement = $(e.target).closest('[data-clickable]');

    if ($('.content-block-tout__inner img', $blockElement).length > 0) {
      if (window.location.hash) {
        // Fix for hitchhiking hashes
        history.replaceState('', '', $blockElement.data('clickable'));
      }
      window.location.href = $blockElement.data('clickable');
    }
  });
})(jQuery);
